import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import "@fontsource/abel"; // Agency FB

import { Navigation, Footer, Date } from "./index";

import "@fontsource/asap";
import "@fontsource/open-sans";

interface BuyNowCardProps {
  price: number;
  quantity: string;
  img: () => JSX.Element;
  title: string;
}

const CBDOil = (): JSX.Element => (
  <StaticImage
    alt="CBD Oil"
    src="../../../../images/cansativa_leaf_oil.png"
    loading="eager"
    objectFit="cover"
  />
);

const CBDLotion = (): JSX.Element => (
  <StaticImage
    alt="CBD lotion"
    src="../../../../images/cansativa_lotion.png"
    loading="eager"
    objectFit="cover"
  />
);

const CBDLotionIcon = (): JSX.Element => (
  <div className="relative">
    <div className="absolute top-0 right-0 rounded-full text-center bg-cansativa-organics-purple-800 text-white px-3 py-2 z-10 italic text-xs h-20 w-20 flex items-center justify-center" style={{ fontFamily: "Open Sans" }}>
      DOUBLE STRENGTH!
    </div>
    <StaticImage
      alt="CBD lotion"
      src="../../../../images/cansativa_lotion.png"
      loading="eager"
      objectFit="cover"
    />
  </div>
);

const BodyOil = (): JSX.Element => (
  <StaticImage
    alt="CBD Body Oil"
    src="../../../../images/cansativa_oil.png"
    loading="lazy"
    objectFit="cover"
  />
);

const CBDCapsules = (): JSX.Element => (
  <StaticImage
    alt="CBD Capsules"
    src="../../../../images/cansativa_hand.png"
    loading="lazy"
    objectFit="cover"
  />
);

const BuyNowCard = ({ img, title, quantity, price }: BuyNowCardProps): JSX.Element => {
  const Img: () => JSX.Element = img;

  return (
    <div className="bg-gray-300 max-w-sm rounded-xl p-5 pt-8 items-center m-6" style={{ fontFamily: "Asap" }}>
      <Img />
      <div className="flex flex-col space-y-1 text-center w-full text-cansativa-organics-gray-900">
        <p className="font-bold">{title}</p>
        <p className="text-sm" style={{ fontFamily: "Open Sans" }}>{quantity}</p>
        <p className="font-extrabold text-xl">${price}</p>
        <button className="mx-24 px-2 py-1 rounded-lg bg-cansativa-organics-emerald-800 text-cansativa-organics-gray-50 outline-none focus:ring-4 focus:ring-gray-500">ADD TO CART</button>
      </div>
    </div>
  );
};

const BuyNowCards = (): JSX.Element => {
  const cards: BuyNowCardProps[] = [
    {
      img: CBDCapsules,
      price: 65.99,
      quantity: "50 capsules",
      title: "Cansativa CBD Capsules",
    },
    {
      img: CBDCapsules,
      price: 99.99,
      quantity: "100 capsules",
      title: "Cansativa CBD Capsules"
    },
    {
      img: CBDOil,
      price: 68.99,
      quantity: "1 oz / 25mg",
      title: "Cansativa Oral CBD Oil"
    },
    {
      img: CBDOil,
      price: 52.99,
      quantity: "1 oz / 12mg",
      title: "Cansativa Oral CBD Oil"
    },
    {
      img: CBDLotion,
      price: 56.99,
      quantity: "16 oz",
      title: "Cansativa CBD Body Cream"
    },
    {
      img: CBDLotionIcon,
      price: 92.99,
      quantity: "16 oz",
      title: "Cansativa CBD Body Cream Double Strength"
    },
    {
      img: BodyOil,
      price: 69.99,
      quantity: "3 oz",
      title: "Cansativa CBD Body Oil"
    }
  ];

  return (
    <>
      {cards.map((card, index) => (
        <BuyNowCard
          title={card.title}
          quantity={card.quantity}
          price={card.price}
          img={card.img}
          key={index}
        />
      ))}
    </>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 text-black" style={{ fontFamily: "Open Sans" }}>
      <p className="mt-5">Don’t buy your CBD from an unreputable company. Recent studies have shown that CBD from many companies contains less CBD than they say, and some do not contain ANY CBD! In addition, some CBD products contain nasty chemicals like pesticides, heavy metals, and other contaminants.</p>
      <p className="mt-5">The Food and Drug Administration has even made some companies stop promoting their products because they made claims they could not support.</p>
      <p className="mt-5">The usual CBD formulation is oil, but CDB is also sold as an extract, a vaporized liquid and an oil-based capsule. Food, drinks and beauty products are among the many CBD-infused products available online.</p>
      <h2 className="text-3xl font-bold text cansativa-organics-gray-800" style={{ fontFamily: "Asap" }}>Our Goals</h2>
      <p className="mt-5">We select only the best produucts to deliver to our customers. Every day we study dozens of CBD products and speak with experts. We strive to meet the highest standards.</p>
      <div className="flex flex-row flex-wrap mt-6 place-items-center justify-center lg:px-20 lg:mx-20">
        <BuyNowCards />
      </div>
    </div>
  );
};

const CansativaProductsPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100 " style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Date />
      <Footer />
    </div>
  );
};

export default CansativaProductsPage;
